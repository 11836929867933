<template>
  <div class="w-100">
    <div class="d-flex flex-row justify-content-start">
      <div style="margin-right: 0.725rem" class="align-self-center">
        <light-box :items="itemInfo.imageItems" :index="index" :effect="'fade'" @close="index = null" />
        <div class="cursor-pointer" @click="showImage">
          <b-img-lazy :rounded="true" width="75px" height="75px" :src="itemInfo.imageUrl || defaultImg" />
        </div>
      </div>
      <div class="d-flex flex-column w-100">
        <div class="font-weight-bold">
          {{ itemInfo.title }}
        </div>
        <div class="d-flex flex-row justify-content-start align-items-center">
          <div class="d-flex flex-column w-75">
            <div class="row">
              <div class="col-xl-4 col-lg-6 col-md-12">
                <span class="text-warning font-weight-bold">Account No:</span> {{ itemInfo.accountNo ? itemInfo.accountNo : '-' }}
              </div>
              <div class="col-xl-8 col-lg-6 col-md-12">
                <span class="text-warning font-weight-bold">Company:</span> {{ itemInfo.companyName ? itemInfo.companyName : '-' }}
              </div>
            </div>
            <div class="row">
              <div class="col-xl-4 col-lg-6 col-md-12">
                <span class="text-warning font-weight-bold">UPC:</span> {{ itemInfo.upc ? itemInfo.upc : '-' }}
              </div>
              <div class="col-xl-8 col-lg-6 col-md-12">
                <span class="text-warning font-weight-bold">MSKU:</span> {{ itemInfo.msku ? itemInfo.msku : '-' }}
              </div>
            </div>
            <div class="row">
              <div class="col-xl-4 col-lg-6 col-md-12">
                <span class="text-warning font-weight-bold">ASIN:</span> <a :href="`https://www.amazon.com/gp/product/${itemInfo.asin}`" target="_blank">{{ itemInfo.asin ? itemInfo.asin : '-' }}</a>
              </div>
              <div class="col-xl-8 col-lg-6 col-md-12">
                <span class="text-warning font-weight-bold">QTY #:</span> {{ itemInfo.quantity }}
              </div>
            </div>
            <div class="row">
              <div class="col-xl-4 col-lg-6 col-md-12">
                <span class="text-warning font-weight-bold">FNSKU:</span> {{ itemInfo.fnsku }}</div>
              <div class="col-xl-8 col-lg-6 col-md-12">
                <span class="text-warning font-weight-bold">Exp Date:</span> {{ formatedDate(itemInfo.expirationDate) }}
              </div>
            </div>
          </div>
          <div class="flex-grow-1">
            <span class="text-warning font-weight-bold">Location:</span> {{ getLocationName(itemInfo.locationName) }}
          </div>
          <div v-if="itemInfo.notes">
            <div :id="`info-note-${itemInfo.uid}`" class="h3 mb-0 align-self-center" @mouseenter="toggleWithAnimation" @mouseleave="toggleWithAnimation">
              <b-icon icon="exclamation-circle-fill" variant="warning" :animation="iconAnimation" />
            </div>
            <dx-tooltip :target="`#info-note-${itemInfo.uid}`" :position="'top'" :animation="animationConfig" :visible="withAnimationVisible" :close-on-outside-click="false">
              {{ itemInfo.notes }}
            </dx-tooltip>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable vue/no-unused-components */

import CoolLightBox from 'vue-cool-lightbox'
import { v4 as uuidv4 } from 'uuid'
import 'vue-cool-lightbox/dist/vue-cool-lightbox.min.css'
import { formatDateTime } from '@core/utils/filter'
import { DxTooltip } from 'devextreme-vue/tooltip'

const defaultImageUrl = require('@/assets/images/undraw/no_images.svg')

export default {
  components: {
    'light-box': CoolLightBox,
    'dx-tooltip': DxTooltip,
  },
  props: {
    itemData: {
      type: Object,
      required: false,
      default: () => {},
    },
  },
  data() {
    return {
      index: null,
      withAnimationVisible: false,
      iconAnimation: 'throb',
      animationConfig: {
        show: {
          type: 'pop',
          from: {
            scale: 0.1,
            opacity: 0,
          },
          to: {
            scale: 1,
            opacity: 1,
          },
        },
        hide: {
          type: 'pop',
          from: {
            scale: 1,
            opacity: 1,
          },
          to: {
            scale: 0.1,
            opacity: 0,
          },
        },
      },
      defaultImg: defaultImageUrl,
    }
  },
  computed: {
    itemInfo() {
      const imageItems = [this.itemData.largeImage]
      return {
        uid: uuidv4(),
        accountNo: this.itemData.accountNo,
        companyName: this.itemData.companyName,
        title: this.itemData.title,
        productType: this.itemData.productType,
        quantity: this.itemData.quantity,
        notes: this.itemData.notes,
        asin: this.itemData.asin,
        fnsku: this.itemData.fnsku,
        msku: this.itemData.msku,
        upc: this.itemData.upc,
        location: this.itemData.location,
        locationName: this.itemData.locationName,
        expirationDate: this.itemData.expirationDate,
        imageUrl: this.itemData.smallImage,
        imageItems: imageItems,
      }
    },
  },
  methods: {
    formatedDate(date) {
      if (date) {
        return formatDateTime(date, {
          year: 'numeric',
          month: 'numeric',
          day: 'numeric',
        })
      }
      return null
    },
    toggleWithAnimation() {
      this.iconAnimation = this.withAnimationVisible ? 'throb' : ''
      this.withAnimationVisible = !this.withAnimationVisible
    },
    showImage() {
      this.index = 0
    },
    getLocationName(location) {
      if (location) {
        return location.name
      }
      return '-'
    },
  },
}
</script>

<style></style>
